import { camelCase, snakeCase } from 'lodash';

export const configToSnakeCase = (config) => {
  const { params, data } = config;

  return {
    ...config,
    params: keysSyntaxModifier(snakeCase, params),
    data: keysSyntaxModifier(snakeCase, data),
  };
};

export const responseToCamelCase = (response) => {
  const { data } = response;

  return {
    ...response,
    data: keysSyntaxModifier(camelCase, data),
  };
};

const systemKeys = ['_destroy'];
const numberedKeys = ['has_email2', 'has_email3'];

const isCustomAttribute = (key) =>
  /^(gte_|lte_)?_([0-9a-f]{8})_([A-Za-z_]+)/.test(key);

const shouldSkipKeySyntaxModifier = (key) =>
  isCustomAttribute(key) ||
  systemKeys.includes(key) ||
  numberedKeys.includes(key);

const keysSyntaxModifier = (syntaxModifier, object) => {
  // Recursive function to pass through each nested object to change keys syntax
  const appliedSyntaxToObject = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => appliedSyntaxToObject(v));
    }
    if (isFormData(obj)) {
      const modifiedFormData = new FormData();

      Array.from(obj).forEach(([key, value]) => {
        modifiedFormData.append(
          syntaxModifier(key),
          keysSyntaxModifier(syntaxModifier, value),
        );
      });

      return modifiedFormData;
    }
    if (isADefinedObject(obj)) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [shouldSkipKeySyntaxModifier(key) ? key : syntaxModifier(key)]:
            appliedSyntaxToObject(obj[key]),
        }),
        {},
      );
    }

    return obj;
  };

  return appliedSyntaxToObject(object);
};

const isADefinedObject = (object) =>
  object !== null && object !== undefined && object.constructor === Object;

const isFormData = (object) => object?.constructor === FormData;
